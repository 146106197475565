import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode:'history',
    base: process.env.BASE_URL,
    //router,
    
  //linkActiveClass: "sublink",
  //linkExactActiveClass: "sublink-active",
    scrollBehavior () {
    return { x: 0, y: 0 }
    },
    
    routes: [
        {
          path: '/loading',
          name: 'loading',
          props: (route) => ({
            guid: route.query.guid,
          }),
          component: () => import('./views/LoadingPage.vue')
        },
        {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
              {
                path: '/',
                name: 'home',
                props: (route) => ({
                  guid: route.query.guid
                }),
                component: () => import('./views/Home.vue')
              },
             // {
               // path: '/caso/:id',
              // name: 'caso',
               // component: () => import('./views/Caso/Caso.vue')
            //  },
              {
                path: '/nuevoCaso',
                name: 'nuevoCaso',
                component: () => import('./views/NuevoCaso.vue')
              },
              {
                path: '/nuevaCotizacion',
                name: 'nuevaCotizacion',
                component: () => import('./views/NuevaCotizacion.vue')
              },
              {
                path: '/reportesCredito',
                name: 'reportesCredito',
                component: () => import('./views/ReportesCreditoList.vue')
              },
              {
                path: '/buroCredito',
                name: 'buroCredito',
                component: () => import('./views/BuroCreditoList.vue')
              },
              {
              path: '/ReporteUser/:id?',
              name: 'ReporteUser',
              component: () => import('@/views/Caso/ReporteCreditoUser.vue')
              },
              {
                path: '/ConsultaBuroCredito/:id?',
                name: 'ConsultaBuroCredito',
                component: () => import('@/views/Caso/ConsultaBuroCredito.vue')
              },
              {
              path: '/ReporteCoacreditado/:email?/:id?',
              name: 'ReporteCoacreditado',
              component: () => import('@/views/Caso/ReporteCreditoCoacreditado.vue')
              },
              {
                path: '/ReporteUserNew',
                name: 'ReporteUserNew',
                component: () => import('@/views/Caso/ReporteCreditoUserNew.vue')
                },
                
              {////COntenedor de precalificaciones soc
                path: '/PrecaSoc/:id?',
                name: 'PrecaSoc',
                component: () => import('@/views/Caso/PrecalificacionesSOC/PrecalificacionesSoc.vue')
                },
              {////Acceso de precalificador
                path: '/AccesoPrecalificador',
                name: 'AccesoPrecalificador',
                component: () => import('./views/Caso/Precalificador/Precalificador.vue')

                
                },

              //{
              //  path: '/perfil',
              //  name: 'perfil',
              //  component: () => import('./views/Perfil/Perfil.vue')
              //},
              {
                path: '/faqs',
                name: 'faqs',
                component: () => import('./views/Faqs.vue')
              },
              //{
              //  path: '/perfilInmobiliaria',
              //  name: 'perfilInmobiliaria',
              //  component: () => import('./views/PerfilInmobliaria/Perfil.vue')
              //},
              //{
              //  path: '/inmobiliaria',
              //  name: 'inmobiliaria',
              //  component: () => import('./views/Inmobiliaria.vue')
              //},
              {
                path: '/solicitante',
                name: 'solicitante',
                component: () => import('./views/CasoInmobiliaria/Solicitante.vue')
              },
              {
                path: '/tutoriales',
                name: 'tutoriales',
                component: () => import('./views/Tutoriales/tutoriales.vue')
              },
              {
                path: '/credito',
                name: 'credito',
                component: () => import('./views/CasoInmobiliaria/Credito.vue')
              },
              {
                path: '/notas',
                name: 'notas',
                component: () => import('./views/Caso/Notas.vue')
              },
             // {
               // path: '/credito',
                //name: 'credito',
                //component: () => import('./views/Caso/Credito.vue')
              //},
              {
                path: '/reporteCredito',
                name: 'reporteCredito',
                component: () => import('./views/Caso/Personales/ReporteCredito.vue')
              },
              {
                path: '/personales',
                name: 'personales',
                component: () => import('./views/Caso/Personales/Personales.vue')
              },
              {
                path: '/sisec',
                name: 'sisec',
                component: () => import('./views/Caso/SISEC.vue')
              },
              {
                path: '/imprimir/:id',
                name: 'imprimir',
                component: () => import('./views/Caso/ImprimirFavs.vue')
              },
              {
                path: '/imprimirFav/:id',
                name: 'imprimirFav',
                component: () => import('./views/Caso/imprimirFavsCaso.vue')
              },
              {
                path: '/Home_inmo',
                name: 'Home_inmo',
                component: () => import('./views/CasoInmobiliaria/Ase_inmobiliaria/Home_inmo_new.vue')
              },
              //{
              //  path: '/nuevaCotizacionInmobiliaria',
              //  name: 'nuevaCotizacionInmobiliaria',
              //  component: () => import('./views/CotizacionInmobiliaria.vue')
              //},
              //{
              //  path: '/resultados/:id',
              //  name: 'resultados',
              //  component: () => import('./views/CasoInmobiliaria/Resultados.vue')
              //}
            ],
        },


        {
          // =============================================================================
          // MAIN LAYOUT ROUTES INMOBILIARIO
          // =============================================================================
                  path: '',
                  component: () => import('./layouts/main/MainInmobiliaria.vue'),
                  children: [
                    {
                      path: '/homeInmo',
                      name: 'HomeInmo',
                      component: () => import('@/views/CasoInmobiliaria/nuevasVistas/Home.vue')
                    },
                    {
                      path: '/inmobiliaria/:id',
                      name: 'inmobiliaria',
                      component: () => import('@/views/Inmobiliaria.vue')
                    },
                    {
                      path: '/Precalifica',
                      name: 'PrecalificaInmo',
                      component: () => import('@/views/CasoInmobiliaria/nuevasVistas/Precalifica.vue')
                    },
                    {
                      path: '/Calculadora',
                      name: 'CalculadoraInmo',
                      component: () => import('@/views/CasoInmobiliaria/nuevasVistas/Calculadora/Calculadora.vue')
                    },
                    {
                      path: '/productos/:id',
                      name: 'productos',
                      component: () => import('@/views/CasoInmobiliaria/nuevasVistas/Calculadora/CalculadoraProducto.vue')
                    },
                    {
                      path: '/nuevaCotizacionInmobiliaria',
                      name: 'nuevaCotizacionInmobiliaria',
                      component: () => import('@/views/CotizacionInmobiliaria.vue')
                    },
                    {
                      path:'/casoInmobiliaria/:id',
                      name: 'casoInmobiliaria',
                      component: () => import('@/views/CasoInmobiliaria/Caso.vue')
                    },
                    {
                      path: '/imprimirFavInmo/:id',
                      name: 'imprimirFavInmo',
                      component: () => import('./views/CasoInmobiliaria/imprimirFavsCaso.vue')
                    },
                    {
                      path: '/perfilInmobiliaria',
                      name: 'perfilInmobiliaria',
                      component: () => import('@/views/PerfilInmobliaria/Perfil.vue')
                    },   
                    {
                      path: '/Calculadora-datos',
                      name: 'Calculadora-datos',
                      component: () => import('@/views/CasoInmobiliaria/nuevasVistas/Calculadora/Calculadora-datos.vue')
                    },   
                    {
                      path: '/Calculadora-producto',
                      name: 'Calculadora-producto',
                      component: () => import('@/views/CasoInmobiliaria/nuevasVistas/Calculadora/Calculadora-producto.vue')
                    },   
                    {
                      path: '/datos-perfil-inmo',
                      name: 'datos-perfil-inmo',
                      component: () => import('@/views/CasoInmobiliaria/nuevasVistas/datos-perfil-inmo.vue')
                    },

                  ]
        },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
        // =============================================================================
        // PAGES
        // =============================================================================
              {
                path: '/pages/error-404',
                name: 'page-error-404',
                component: () => import('@/views/pages/Error404.vue')
              },
              {
                path: '/login',
                name: 'login',
                component: () => import('@/views/Login.vue')
              },
              {
                path: '/recuperarcontrasena',
                name: 'recuperarcontrasena',
                component: () => import('@/views/RecuperarContraseña.vue')
              },
              {
                path: '/perfil',
                name: 'perfil',
                component: () => import('@/views/Perfil/Perfil.vue')
              },
              {
                path: '/caso/:id',
                name: 'caso',
                component: () => import('@/views/Caso/Caso.vue')
              },                                    
              {
                path: '/resultados/:id',
                name: 'resultados',
                component: () => import('@/views/CasoInmobiliaria/Resultados.vue')
              },
              {
                path: '/cambiarcontrasena/:id',
                name: 'cambiarcontrasena',
                component: () => import('@/views/CambiarContraseña.vue')
              },
              
              {
                path: '/buro/:id',
                name: 'buro',
                component: () => import('@/views/Caso/ImprimirBuro.vue')
              },

              {
                path: '/reporteBuroCredito/:id',
                name: 'reporteBuroCredito',
                component: () => import('@/views/Caso/ImprimirReporteBuroCredito.vue')
              },

              {
                path: '/buroInmobiliaria/:id/:caso',
                name: 'buroInmobiliaria',
                component: () => import('@/views/CasoInmobiliaria/ImprimirBuro.vue')
              },             
              {
                path: '/imprimirInmoniliaria/:id',
                name: 'imprimirInmoniliaria',
                component: () => import('./views/CasoInmobiliaria/ImprimirFavs.vue')
              },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = "none";
    }
})

export default router
