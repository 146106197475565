import Vue from 'vue'
import App from './App.vue'





// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css' //Material Icons
import 'vuesax/dist/vuesax.css'; // Vuesax
import moment from 'moment'
import VueNumberFormat from 'vue-number-format'
//import VueSocialChat from 'vue-social-chat'

//Vue.use(VueSocialChat)
// Prefered: as a plugin (directive + filter) + custom placeholders support
import VueMask from 'v-mask'
Vue.use(VueMask);

Vue.use(VueNumberFormat, {prefix: '$ ', decimal: '.', thousand: ','})

Vue.prototype.moment = moment

Vue.use(Vuesax)
var VueCookie = require('vue-cookie');
Vue.use(VueCookie);
// axios
import axios from "./axios.js"
Vue.prototype.$axios = axios


// Theme Configurations
import '../themeConfig.js'

import './sidebar.js'
// Globally Registered Components
import './globalComponents.js'
import './activeLink'


// Styles: SCSS
import './assets/scss/main.scss'


// Tailwind
import '@/assets/css/main.css'


// Vue Router
import router from './router'



// Vuex Store
import store from './store/store'


// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from 'vue2-hammer'
Vue.use(VueHammer)


// PrismJS
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'


// Feather font icon
require('./assets/css/iconfont.css')

import VeeValidate from 'vee-validate'
Vue.use(VeeValidate)


Vue.config.productionTip = false
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

import VueApexCharts from "vue3-apexcharts";
Vue.use(VueApexCharts)

